import { AxiosInstance } from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { Unit } from '../interfaces/entities/unit.interface';
import { GetManyResponse } from '../interfaces/get-many-response.interface';
import { User, USER_ROLES } from '../interfaces/user.interface';
import { ADMIN_API_URL, LOCALES } from '../utils/constants';
import { AuthStore } from './auth.store';

const FETCH_NURSES_BY_INSTITUTION_ID_LIMIT = 100;
const FETCH_NURSES_BY_DEPARTMENT_ID_LIMIT = 100;
const FETCH_USERS_BY_UNIT_ID_LIMIT = 100;

export class UsersStore {
  allNursesByInstitutionId: Record<Unit['id'], User[]> = {};
  allNursesByDepartmentId: Record<Unit['id'], User[]> = {};
  allDeactivatedUsersByUnitId: Record<Unit['id'], User[]> = {};
  loadingAllNursesByInstitutionId: Record<Unit['id'], boolean> = {};
  loadingAllNursesByDepartmentId: Record<Unit['id'], boolean> = {};
  loadingAllDeactivatedUsersByUnitId: Record<Unit['id'], boolean> = {};

  constructor(
    protected readonly api: AxiosInstance,
    private readonly authSore: AuthStore,
  ) {
    makeObservable(this, {
      allNursesByInstitutionId: observable,
      loadingAllNursesByInstitutionId: observable,
      allNursesByDepartmentId: observable,
      allDeactivatedUsersByUnitId: observable,
      loadingAllNursesByDepartmentId: observable,
      loadingAllDeactivatedUsersByUnitId: observable,
      updateLanguage: action,
      fetchAllNursesFromInstitution: action,
      fetchAllNursesFromDepartment: action,
      fetchAllDeactivatedUsersFromUnit: action,
    });
  }

  async updateLanguage(language: LOCALES) {
    try {
      const url = new URL(ADMIN_API_URL);
      url.pathname = 'user';

      const { data } = await this.api.put<User>(url.toString(), {
        language,
      });

      runInAction(() => {
        if (this.authSore.me) {
          this.authSore.me = { ...this.authSore.me, language: data.language };
        }
      });

      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  async updateRole(role: USER_ROLES) {
    try {
      const { data } = await this.api.patch<User>('/users/me', {
        role,
      });
      runInAction(() => {
        this.authSore.me = data;
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  async fetchAllNursesFromInstitution(institutionId: string, force = false) {
    if (!force && this.allNursesByInstitutionId[institutionId]) {
      return;
    }

    if (this.loadingAllNursesByInstitutionId[institutionId]) {
      return;
    }

    runInAction(() => {
      this.loadingAllNursesByInstitutionId[institutionId] = true;
    });
    let more = true;
    let page = 1;
    const arr: User[] = [];
    while (more) {
      const searchParams = new URLSearchParams();
      searchParams.append('page', '' + page);
      searchParams.append('limit', '' + FETCH_NURSES_BY_INSTITUTION_ID_LIMIT);
      searchParams.append('sort', `firstName,ASC`);

      try {
        const {
          data: { data, pageCount },
        } = await this.api.get<GetManyResponse<User>>(
          `institutions/${institutionId}/nurses?${searchParams.toString()}`,
        );
        arr.push(...data);
        more = (pageCount || 0) > page;
        page += 1;
      } catch (err) {
        console.error(err);
        break;
      }

      runInAction(() => {
        this.allNursesByInstitutionId[institutionId] = arr;
        this.loadingAllNursesByInstitutionId[institutionId] = false;
      });
    }
  }

  async fetchAllNursesFromDepartment(departmentId: string, force = false) {
    if (!force && this.allNursesByDepartmentId[departmentId]) {
      return;
    }

    if (this.loadingAllNursesByDepartmentId[departmentId]) {
      return;
    }

    runInAction(() => {
      this.loadingAllNursesByDepartmentId[departmentId] = true;
    });
    let more = true;
    let page = 1;
    const arr: User[] = [];
    while (more) {
      const searchParams = new URLSearchParams();
      searchParams.append('page', '' + page);
      searchParams.append('limit', '' + FETCH_NURSES_BY_DEPARTMENT_ID_LIMIT);
      searchParams.append('sort', `firstName,ASC`);

      try {
        const {
          data: { data, pageCount },
        } = await this.api.get<GetManyResponse<User>>(
          `departments/${departmentId}/nurses?${searchParams.toString()}`,
        );
        arr.push(...data);
        more = (pageCount || 0) > page;
        page += 1;
      } catch (err) {
        console.error(err);
        break;
      }

      runInAction(() => {
        this.allNursesByDepartmentId[departmentId] = arr;
        this.loadingAllNursesByDepartmentId[departmentId] = false;
      });
    }
  }

  async fetchAllDeactivatedUsersFromUnit(unitId: string, force = false) {
    if (!force && this.allDeactivatedUsersByUnitId[unitId]) {
      return;
    }

    if (this.loadingAllDeactivatedUsersByUnitId[unitId]) {
      return;
    }

    runInAction(() => {
      this.loadingAllDeactivatedUsersByUnitId[unitId] = true;
    });
    let more = true;
    let page = 1;
    const arr: User[] = [];
    while (more) {
      const searchParams = new URLSearchParams();
      searchParams.append('page', '' + page);
      searchParams.append('limit', '' + FETCH_USERS_BY_UNIT_ID_LIMIT);
      searchParams.append('sort', `firstName,ASC`);

      const search: Record<string, any> = {
        $or: [
          {
            $and: [{ 'userToUnits.deactivatedAt': { $notnull: 1 } }],
          },
        ],
      };
      searchParams.append('s', JSON.stringify(search));

      try {
        const {
          data: { data, pageCount },
        } = await this.api.get<GetManyResponse<User>>(
          `units/${unitId}/users?${searchParams.toString()}`,
        );
        arr.push(...data);
        more = (pageCount || 0) > page;
        page += 1;
      } catch (err) {
        console.error(err);
        break;
      }

      runInAction(() => {
        this.allDeactivatedUsersByUnitId[unitId] = arr;
        this.loadingAllDeactivatedUsersByUnitId[unitId] = false;
      });
    }
  }
}
