import ChatIcon from '@mui/icons-material/Chat';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AlbumCardComponent } from '../../components/albums/album-card.component';
import { FailedLoadingDataComponent } from '../../components/failed-loading-data.component';
import { AlbumCardLoadingComponent } from '../../components/loading/albums/album-card-loading.component';
import { PatientNestedItemsInfiniteScrollComponent } from '../../components/patients/patient-nested-items-infinite-scroll.component';
import { PatientNestedItemsWrapperComponent } from '../../components/patients/patient-nested-items-wrapper.component';
import { useGuestbookFiles } from '../../hooks/guestbook/use-guestbook-files';
import { usePatient } from '../../hooks/patients/use-patient';
import { usePatientNestedItems } from '../../hooks/patients/use-patient-nested-items';
import { usePatientRole } from '../../hooks/patients/use-patient-role';
import { useCreateButton } from '../../hooks/use-create-button';
import { useFavoriteFiles } from '../../hooks/use-favorite-files';
import { useStores } from '../../hooks/use-stores';
import { ProfilePageProps } from '../../interfaces/profile-page-props.interface';
import {
  DEFAULT_GUESTBOOK_ALBUM_NAME,
  FAVORITES_FOLDER_ID,
  FAVORITES_FOLDER_NAME,
  GUESTBOOK_ALBUM_ID,
} from '../../utils/constants';
import { isXmasAlbumId } from '../../utils/is-xmas-album-id';

const LIMIT = 15;
const LOADING_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function AlbumsList({ profileId }: ProfilePageProps) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const { albumsStore: store } = useStores();
  const { t } = useTranslation();

  const { items, loadMore, loading, hasMore, errorLoading, reload } =
    usePatientNestedItems(store, profileId, LIMIT);
  const { item: profile } = usePatient(profileId);

  const { total: totalFavorites, loading: loadingFavorites } =
    useFavoriteFiles(profileId);

  const { total: guestbookFilesTotal, loading: loadingGuestbookFiles } =
    useGuestbookFiles(profileId);

  const { isPatientRoleAdmin, loading: loadingProfileRole } =
    usePatientRole(profileId);

  const { onClick: onClickCreate } = useCreateButton();

  const thisYearXmasAlbum = useMemo(() => {
    if (loading !== false && !items) {
      return;
    }

    return items.find((item) => isXmasAlbumId(item.id, getYear(new Date())));
  }, [loading, items]);

  const handleClickCreateButton = useCallback(() => {
    if (isPatientRoleAdmin) {
      return onClickCreate();
    }
  }, [isPatientRoleAdmin]);

  const showThisYearXmasAlbumFirstly = useMemo(() => {
    return getMonth(new Date()) === 11; // 0 - January, 1 - February ...
  }, []);

  const loadingAlbumsCount = useMemo(() => {
    if (xs || sm) {
      return 2;
    }

    if (md) {
      return 4;
    }

    if (lg) {
      return 6;
    }

    return 10;
  }, [xs, sm, md, lg]);

  return (
    <>
      <PatientNestedItemsWrapperComponent
        onClickCreate={isPatientRoleAdmin ? handleClickCreateButton : undefined}
        titleCreateButton={t('create-album.button')}
        disabledCreateButton={loadingProfileRole !== false}
      >
        <PatientNestedItemsInfiniteScrollComponent
          dataLength={items.length}
          hasMore={hasMore}
          loadMore={loadMore}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={6}
          >
            {showThisYearXmasAlbumFirstly && thisYearXmasAlbum && (
              <Grid
                key={thisYearXmasAlbum.id}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
              >
                <AlbumCardComponent {...thisYearXmasAlbum} />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <AlbumCardComponent
                id={FAVORITES_FOLDER_ID}
                name={FAVORITES_FOLDER_NAME}
                filesCount={totalFavorites}
                Icon={<FavoriteIcon />}
                loadingCount={loadingFavorites !== false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <AlbumCardComponent
                id={GUESTBOOK_ALBUM_ID}
                name={
                  profile?.guestbookAlbumName || DEFAULT_GUESTBOOK_ALBUM_NAME
                }
                Icon={!profile?.guestbookAlbumImage ? <ChatIcon /> : undefined}
                image={profile?.guestbookAlbumImage || undefined}
                filesCount={guestbookFilesTotal}
                loadingCount={loadingGuestbookFiles !== false}
              />
            </Grid>
            {loading !== false || !items ? (
              <>
                {LOADING_ARRAY.slice(0, loadingAlbumsCount).map(
                  (item, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2}
                      >
                        <AlbumCardLoadingComponent />
                      </Grid>
                    );
                  },
                )}
              </>
            ) : (
              <>
                {items.map((item) => {
                  if (
                    showThisYearXmasAlbumFirstly &&
                    thisYearXmasAlbum?.id === item.id
                  ) {
                    return <></>;
                  }

                  return (
                    <Grid
                      key={item.id}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}
                    >
                      <AlbumCardComponent {...item} />
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
          {loading === false && !!errorLoading && (
            <FailedLoadingDataComponent onClick={reload} />
          )}
        </PatientNestedItemsInfiniteScrollComponent>
      </PatientNestedItemsWrapperComponent>
    </>
  );
}

export const AlbumsListContainer = observer(AlbumsList);
