import { User } from '../user.interface';
import { BaseEntity } from './base-entity.interface';
import { Patient } from './patient.interface';

export enum PROFILE_DELETE_REQUEST_STATUSES {
  UNPROCESSED = 'unprocessed',
  REJECTED = 'rejected',
  APPROVED = 'approved',
}

export interface ProfileDeleteRequest extends BaseEntity {
  reason?: string;
  status: PROFILE_DELETE_REQUEST_STATUSES;
  patientId: Patient['id'];
  patient?: Patient;
  createdById?: User['id'];
  createdBy?: User;
}
