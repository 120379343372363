import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { MultiLinesField } from '../../forms/patient/shared/multi-lines-field.component';
import { DeleteButtonComponent } from '../buttons/delete-button.component';
import { DialogComponent } from './dialog.component';

interface DeleteProfileFormProps {
  reason?: string;
}

const MIN_ROWS = 3;

export interface DeleteProfileDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (reason?: string) => void;
}

function DeleteProfileDialog({
  isOpen,
  onCancel,
  onConfirm,
}: DeleteProfileDialogProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleConfirm = useCallback(
    ({ reason }: DeleteProfileFormProps) => {
      onConfirm(reason);
    },
    [onConfirm],
  );

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onCancel}
      title={
        <Typography textAlign={'center'} fontWeight={500} variant="subtitle1">
          {t('patient-edit-delete.modal.title')}
        </Typography>
      }
      fullScreen={fullScreen}
      description={
        <Box>
          <Typography
            pb={2}
            variant="body2"
            color={'inherit'}
            component={'p'}
            whiteSpace={'break-spaces'}
          >
            {t('patient-edit-delete.modal.description')}
          </Typography>
          <Formik<DeleteProfileFormProps>
            initialValues={{
              reason: '',
            }}
            onSubmit={handleConfirm}
            validationSchema={Yup.object<
              Record<keyof DeleteProfileFormProps, Yup.AnySchema>
            >().shape({
              reason: Yup.string().nullable().optional(),
            })}
          >
            {({ handleSubmit }) => (
              <Form>
                <Field name="reason">
                  {(props: FieldProps) => {
                    return (
                      <MultiLinesField
                        {...props}
                        placeholder={t(
                          'patient-edit-delete.modal.reason.label',
                        )}
                        minRows={MIN_ROWS}
                      />
                    );
                  }}
                </Field>

                <Box
                  display="flex"
                  pt={4}
                  sx={(theme) => ({
                    flexDirection: 'column',
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    },
                    ...(fullScreen
                      ? {
                          position: 'absolute',
                          bottom: theme.spacing(2),
                          width: 'calc(100% - 20px)',
                        }
                      : {}),
                  })}
                >
                  <DeleteButtonComponent
                    onClick={() => handleSubmit()}
                    variant={'outlined'}
                    color={'error'}
                    autoFocus
                    sx={(theme) => ({
                      minWidth: '150px',
                      color: theme.palette.error.main,
                    })}
                    text={t('patient-edit-delete.modal.confirm.button')}
                  />
                  <Button
                    autoFocus
                    onClick={onCancel}
                    sx={(theme) => ({
                      minWidth: '80px',
                      flexDirection: 'column',
                      order: '-1',
                      [theme.breakpoints.up('md')]: {
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        order: '0',
                        ml: 1,
                      },
                    })}
                  >
                    {t('patient-edit-delete.modal.cancel.button')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      }
    ></DialogComponent>
  );
}

export const DeleteProfileDialogComponent = memo(DeleteProfileDialog);
