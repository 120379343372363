import { Language } from '../interfaces/language.interface';

// APP TEXT
export const APP_TITLE = 'VilMer | MinMemoria';

export const DRAWER_WIDTH = 250;

export const PERSONA_DRAWER_WIDTH = 220;

export const HEADER_HEIGHT_DESKTOP = 36;

export const STORY_CARD_WIDTH = 345;

export const SUB_NAV_HEIGHT_DESKTOP = 82;
export const SUB_NAV_HEIGHT_MOBILE = 62;

export const PATH_PREFIX = '/profiles';

export enum LOCALES {
  en = 'en',
  nb = 'nb',
  pt = 'pt',
  sv = 'sv',
  da = 'da',
}

export const DEFAULT_LOCALES_LIST: ReadonlyArray<Language> = [
  { value: LOCALES.nb, label: 'Norsk', shortLabel: 'NO' },
  { value: LOCALES.en, label: 'English', shortLabel: 'EN' },
  { value: LOCALES.pt, label: 'Português', shortLabel: 'PT' },
  { value: LOCALES.sv, label: 'Svenska', shortLabel: 'SV' },
  { value: LOCALES.da, label: 'Dansk', shortLabel: 'DA' },
];

export const DEFAULT_LOCALE = DEFAULT_LOCALES_LIST[0];

export const SUPPORT_URL =
  'https://hjelp.vilmer.no/nb/collections/2936752-minmemoria';

export const OPPLEVERSER_MY_SIDE_URL = `${
  process.env.REACT_APP_OPPLEVERSER_URL || 'https://app.vilmer.no'
}/minside`;

export const MINMEMORIA_HOME_URL =
  process.env.REACT_APP_MINMEMORIA_HOME_URL || 'https://www.minmemoria.no/';
export const WEBSITE_NAME = 'minmemoria.no';
export const VILMER_URL =
  process.env.REACT_APP_VILMER_URL || 'https://vilmer.no/';
export const DASHBOARD_LINK =
  process.env.REACT_APP_DASHBOARD_URL || 'https://dashboard.vilmer.no/';
export const VILKAR_URL =
  process.env.REACT_APP_VILKAR_URL || 'https://vilmer.no/vilkar';
export const ADMIN_API_URL =
  process.env.REACT_APP_ADMIN_API_URL || 'https://auth-api.vilmer.no';

export const LOGO_PATH = '/assets/logo.png';
export const VILMER_LOGO_PATH = '/assets/vilmer-logo.png';
export const LOGO_WITHOUT_TEXT_PATH = '/assets/logo-without-text.png';

export const AUTH_TOKEN_KEY =
  process.env.REACT_APP_VILMER_AUTH_COOKIE_KEY || 'access-token';

export const AUTH_TOKEN_DOMAIN =
  process.env.REACT_APP_VILMER_AUTH_DOMAIN || '.vilmer.no';

export const AUTH_TOKEN_EXPIRES = +(
  process.env.REACT_APP_VILMER_AUTH_EXPIRES || 30
);

export const AUTH_REDIRECT_URL_QUERY = 'redirect_url';

export const EMPTY_IMAGE = '/assets/empty-image.png';
export const PLACEHOLDER_AVATAR = '/assets/placeholder-avatar.png';

export const FAVORITES_FOLDER_ID = 'favorites';
export const FAVORITES_FOLDER_NAME = 'Favorite pictures';

export const GUESTBOOK_ALBUM_ID = 'guestbook-content';
export const DEFAULT_GUESTBOOK_ALBUM_NAME = 'Guest book content';

export const INVITATION_IDS_QUERY_NAME = 'invitations';

export const MIN_DATE = new Date('1000-01-01');

export const PATIENT_IDS_URL_QUERY = 'profiles';
export const DEPARTMENT_IDS_URL_QUERY = 'departments';
export const INSTITUTION_IDS_URL_QUERY = 'institutions';
export const MUNICIPALITY_IDS_URL_QUERY = 'municipalities';
